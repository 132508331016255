import React, { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, List, Typography } from "@material-ui/core";

import { useRecentSearchResultsStyles } from "./RecentSearchResultsStyles";
import { SEARCH_TERMS, USER_ID } from "../../../constants/common-constants";
import { fetchRecentSearchResult, resetSearchResult } from "../../../store/ducks/search/actionCreators";
import {
    selectIsRecentSearchResultEmpty,
    selectLoadingSearchResult,
    selectRecentTagsSearchResult,
    selectRecentTextSearchResult,
    selectRecentUsersSearchResult
} from "../../../store/ducks/search/selectors";
import UserSearchResult from "../UserSearchResult/UserSearchResult";
import TextSearchResult from "../TextSearchResult/TextSearchResult";
import Spinner from "../../Spinner/Spinner";

interface RecentSearchResultsProps {
  closePopup?: () => void;
  resetText?: () => void;
}

const RecentSearchResults: FC<RecentSearchResultsProps> = ({ closePopup, resetText }): ReactElement => {
    const classes = useRecentSearchResultsStyles();
    const dispatch = useDispatch();
    const isLoadingSearchResult = useSelector(selectLoadingSearchResult);
    const recentTextSearchResult = useSelector(selectRecentTextSearchResult);
    const recentTagsSearchResult = useSelector(selectRecentTagsSearchResult);
    const recentUsersSearchResult = useSelector(selectRecentUsersSearchResult);
    const isRecentSearchResultEmpty = useSelector(selectIsRecentSearchResultEmpty);

    useEffect(() => {
        const localStorageItem = localStorage.getItem(SEARCH_TERMS);
        const userId = localStorage.getItem(USER_ID);
        if (localStorageItem && userId) {
            const searchTerms = JSON.parse(localStorageItem);
            dispatch(fetchRecentSearchResult(searchTerms[userId]));
        }
    }, []);

    const onClickClearSearchTerms = (): void => {
        dispatch(resetSearchResult());
        const localStorageItem = localStorage.getItem(SEARCH_TERMS);
        const userId = localStorage.getItem(USER_ID);
        if (localStorageItem && userId) {
          const data = JSON.parse(localStorageItem); // Parse the string to an object
          delete data[userId];
          if (Object.keys(data).length) {
            localStorage.setItem(SEARCH_TERMS , JSON.stringify(data));
            return;
          }
          localStorage.removeItem(SEARCH_TERMS);
        }
    };

    return (
        <>
            {isRecentSearchResultEmpty ? (
                <Typography className={classes.searchText} variant={"body1"} component={"div"}>
                    Try searching for people, topics, or keywords
                </Typography>
            ) : (
                isLoadingSearchResult ? (
                    <Spinner />
                ) : (
                    <>
                        <div>
                            <Typography className={classes.header} variant={"h5"} component={"div"}>
                                Recent
                            </Typography>
                            <Button
                                className={classes.clearButton}
                                onClick={onClickClearSearchTerms}
                                variant="text"
                                color="primary"
                            >
                                Clear all
                            </Button>
                        </div>
                        <List className={classes.recentSearchList}>
                          {recentTextSearchResult.map((text, index) => (
                              <TextSearchResult key={index} text={text} recentSearch closePopup={closePopup} resetText={resetText} />
                          ))}
                          {recentTagsSearchResult.map((tag, index) => (
                              <TextSearchResult key={index} text={tag} recentSearch closePopup={closePopup} resetText={resetText} />
                          ))}
                          {recentUsersSearchResult.map((user) => (
                              <UserSearchResult key={user.id} user={user} recentSearch closePopup={closePopup} resetText={resetText} />
                          ))}
                        </List>
                    </>
                )
            )}
        </>
    );
};

export default RecentSearchResults;
