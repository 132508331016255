import React, { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, Theme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createTheme";
import { deepmerge } from "@mui/utils";

import Authentication from "./pages/Authentication/Authentication";
import Home from "./pages/Home/Home";
import { Layout } from "./pages/Layout";
import UserPage from "./pages/UserPage/UserPage";
import { selectIsAuth, selectTokenStatus, selectUserDataId, selectUserStatus } from "./store/ducks/user/selectors";
import { fetchUserData, setNewMention, setNewNotification, setTokenLoadingStatus, setUnreadMessage } from "./store/ducks/user/actionCreators";
import Explore from "./pages/Explore/Explore";
import FollowingFollowers from "./pages/FollowingFollowers/FollowingFollowers";
import TweetImageModal from "./components/TweetImageModal/TweetImageModal";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Bookmarks from "./pages/Bookmarks/Bookmarks";
import Notifications from "./pages/Notifications/Notifications";
import NotificationInfo from "./pages/Notifications/NotificationsPage/NotificationInfo/NotificationInfo";
import Messages from "./pages/Messages/Messages";
import { setChatMessage } from "./store/ducks/chatMessages/actionCreators";
import { WS_URL } from "./constants/endpoint-constants";
import { setNotification, updateNotificationInfoTweet } from "./store/ducks/notifications/actionCreators";
import { selectNotificationsList } from "./store/ducks/notifications/selectors";
import { setScheduledTweets, setTweet, setUpdatedTweet, setVote, setDeletedTweets } from "./store/ducks/tweets/actionCreators";
import Lists from "./pages/Lists/Lists";
import FullList from "./pages/FullList/FullList";
import SuggestedLists from "./pages/SuggestedLists/SuggestedLists";
import ListsMemberships from "./pages/Lists/ListsMemberships/ListsMemberships";
import Settings from "./pages/Settings/Settings";
import {
    blueColor,
    crimsonColor,
    defaultTheme,
    dimTheme,
    greenColor,
    lightsOutTheme,
    orangeColor,
    violetColor,
    yellowColor
} from "./theme";
import NotificationsTimeline from "./pages/Notifications/NotificationsPage/NotificationsTimeline/NotificationsTimeline";
import FollowersYouKnow from "./pages/FollowersYouKnow/FollowersYouKnow";
import { fetchTags } from "./store/ducks/tags/actionCreators";
import { fetchRelevantUsers } from "./store/ducks/users/actionCreators";
import UserImageModal from "./pages/UserPage/UserImageModal/UserImageModal";
import {
    ACCOUNT_FORGOT,
    ACCOUNT_LOGIN,
    ACCOUNT_SIGNIN,
    BOOKMARKS,
    HOME,
    HOME_CONNECT,
    HOME_TRENDS,
    HOME_TWEET,
    LAUNCH_SOON,
    LISTS,
    LISTS_MEMBERSHIPS,
    MESSAGES,
    MODAL,
    NEWS,
    NOTIFICATION,
    NOTIFICATIONS,
    NOTIFICATIONS_TIMELINE,
    PROFILE,
    PROFILE_HEADER_PHOTO,
    PROFILE_PHOTO,
    QUOTES,
    SEARCH,
    SETTINGS,
    SUGGESTED,
    TOPICS,
    USER,
    USER_FOLLOWERS_YOU_FOLLOW
} from "./constants/path-constants";
import QuoteTweets from "./pages/QuoteTweets/QuoteTweets";
import { BackgroundTheme, ColorScheme, LoadingStatus } from "./types/common";
import ActionSnackbar from "./components/ActionSnackbar/ActionSnackbar";
import FullTweet from "./pages/FullTweet/FullTweet";
import Connect from "./pages/Connect/Connect";
import Trends from "./pages/Trends/Trends";
import Topics from "./pages/Topics/Topics";
import UserTopics from "./pages/UserTopics/UserTopics";
import {
    TOPIC_CHAT,
    TOPIC_DELETE_REPLY_TWEET,
    TOPIC_DELETE_TWEET,
    TOPIC_FEED,
    TOPIC_FEED_ADD,
    TOPIC_FEED_SCHEDULE,
    TOPIC_FEED_VOTE,
    TOPIC_MENTIONS,
    TOPIC_NOTIFICATIONS,
    TOPIC_PING_VOTE,
    TOPIC_REJECT_TWEET,
    TOPIC_USER_UPDATE
} from "./constants/ws-constants";
import { APP_NAME, BACKGROUND, COLOR, IS_USA_TIME, PRODUCTION_DOMAIN, TOKEN, USER_ID } from "./constants/common-constants";
import { setUpdatedListTweet, setVoteListTweet } from "./store/ducks/list/actionCreators";
import { setUpdatedUserTweet } from './store/ducks/userTweets/actionCreators';
import LaunchSoon from './pages/LaunchSoon/LaunchSoon';
import { deleteTweetFromReplay } from './store/ducks/tweet/actionCreators';
import NewsList from './pages/News/News';

const App: FC = (): ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const myProfileId = useSelector(selectUserDataId);
    const notifications = useSelector(selectNotificationsList);
    const isAuth = useSelector(selectIsAuth);
    const loadingStatus = useSelector(selectUserStatus);
    const tokenStatus = useSelector(selectTokenStatus);
    const isReady = loadingStatus !== LoadingStatus.NEVER && loadingStatus !== LoadingStatus.LOADING;
    const [colorScheme, setColorScheme] = useState<ThemeOptions>(blueColor as ThemeOptions);
    const [theme, setTheme] = useState<Theme>(defaultTheme);

    const location = useLocation<{ background: any }>();
    const background = location.state && location.state.background;
    const IS_LAUNCH_SOON_PAGE = location.pathname.includes(LAUNCH_SOON);
    const userId = localStorage.getItem(USER_ID) ? Number(localStorage.getItem(USER_ID)) : null;

    useEffect(() => {
        dispatch(fetchUserData());

        if (IS_USA_TIME && document) {
            let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = `${process.env.PUBLIC_URL}/twitter.ico`;
        }
        document.title = APP_NAME;

        if (!isAuth && isReady && !location.pathname.includes(ACCOUNT_LOGIN) && !IS_LAUNCH_SOON_PAGE) {
          if (window?.location.host === PRODUCTION_DOMAIN) history.push(LAUNCH_SOON); else history.push(ACCOUNT_SIGNIN);
        }
        if (!localStorage.getItem(TOKEN) && !IS_LAUNCH_SOON_PAGE) {
          if (window?.location.host === PRODUCTION_DOMAIN) history.push(LAUNCH_SOON); else history.push(ACCOUNT_SIGNIN);
        }
        if (location?.pathname === '/') {
            history.push(HOME);
        }
        const background = localStorage.getItem(BACKGROUND) as BackgroundTheme | null;
        if (background && Object.values(BackgroundTheme).includes(background)) {
          processBackgroundColor(background);
        }

        const color = localStorage.getItem(COLOR) as ColorScheme | null;
        if (color && Object.values(ColorScheme).includes(color)) {
          processColorScheme(color);
        }
    }, []);

    useEffect(() => {
        let stompClient = Stomp.over(new SockJS(WS_URL));
    
        // Error handling for connection and subscription
        stompClient.connect({}, () => {
            console.log("Connected successfully");
    
            // Subscribe to TOPIC_FEED with error handling
            stompClient?.subscribe(TOPIC_FEED, (response) => {
                let data = JSON.parse(response.body);
                data = {
                  ...data,
                  performAction: data?.user?.id === userId,
                };
                dispatch(setUpdatedTweet(data));
                dispatch(setUpdatedListTweet(data));
                dispatch(updateNotificationInfoTweet(data));
                if (window?.location?.pathname?.includes(PROFILE)) {
                  dispatch(setUpdatedUserTweet(data));
                }
            });
    
            // Subscribe to TOPIC_FEED_ADD with error handling
            stompClient?.subscribe(TOPIC_FEED_ADD + userId, (response) => {
                const tweet = JSON.parse(response.body);
                console.log('TOPIC_FEED_ADD', tweet);
                const shouldHideTweet = tweet?.author.isPrivateProfile && !tweet.author.isFollower && myProfileId !== tweet?.author.id
                if (!shouldHideTweet) {
                    dispatch(setTweet(tweet));
                }
            });
    
            // Subscribe to TOPIC_FEED_SCHEDULE with error handling
            stompClient?.subscribe(TOPIC_FEED_SCHEDULE, (response) => {
                dispatch(setScheduledTweets(JSON.parse(response.body)));
            });
    
            // Subscribe to TOPIC_FEED_VOTE with error handling
            stompClient?.subscribe(TOPIC_FEED_VOTE, (response) => {
                dispatch(setVote(JSON.parse(response.body)));
                dispatch(setVoteListTweet(JSON.parse(response.body)));
            });
    
            // Listen for pong responses with error handling
            stompClient?.subscribe('/topic/queue/pong', (response) => {
                console.log('Received pong:', response.body);
            });

            // Subscribe Load delete tweet
            stompClient?.subscribe(TOPIC_DELETE_TWEET, (response: { body: string; }) => {
                if (response.body) {
                  dispatch(setDeletedTweets(JSON.parse(response.body)));
                }
            });

            // Subscribe Load delete reply tweet
            stompClient?.subscribe(TOPIC_DELETE_REPLY_TWEET, (response: { body: string; }) => {
              if (response.body) {
                const data = JSON.parse(response.body);
                if (data?.replyId) dispatch(deleteTweetFromReplay(data?.replyId));
              }
            });
    
            // Send ping 10 times every 3 seconds
            // let count = 0;
            // let intervalId = setInterval(() => {
            //     if (stompClient?.connected && count < 10) {
            //         console.log(`Sending ping ${count + 1}`);
            //         stompClient.send("/app/ping", {}, "ping");
            //         count++;
            //     } else {
            //         clearInterval(intervalId);
            //     }
            // }, 5000); // 3000 milliseconds = 3 seconds
    
        }, (error: any) => {
            console.error("Error connecting to WebSocket server:", error);
        });
    
        // Handle WebSocket disconnection
        stompClient.onDisconnect = () => {
            console.log("Disconnected from WebSocket server");
            // Attempt reconnection or handle disconnection logic here
        };
    
        // Cleanup function to disconnect the stompClient when the component unmounts
        return () => {
            if (stompClient?.connected) {
                stompClient.disconnect(() => {
                    console.log("Disconnected from WebSocket");
                });
            }
        };
    }, [dispatch]);
    
    useEffect(() => {
        let stompClient = Stomp.over(new SockJS(WS_URL));
        if (myProfileId) {
          if (location.pathname !== HOME_CONNECT) {
              dispatch(fetchRelevantUsers());
          }
          dispatch(fetchTags());

          stompClient.connect({}, () => {
              stompClient?.subscribe(TOPIC_CHAT(myProfileId), (response) => {
                  dispatch(setChatMessage(JSON.parse(response.body)));

                  if (myProfileId !== JSON.parse(response.body).author.id) {
                      dispatch(setUnreadMessage(JSON.parse(response.body)));
                  }
              });

              stompClient.subscribe(TOPIC_REJECT_TWEET, (message) => {
                // {"tweet":"> hell","message":"Your tweet is in under review"}
                console.log(message.body);
              });

              stompClient.subscribe(TOPIC_USER_UPDATE, (message) => {
                console.log(message.body);
              });

              stompClient?.subscribe(TOPIC_NOTIFICATIONS(myProfileId), (response) => {
                  const isNotificationExist = notifications.find(notification => notification.id === JSON.parse(response.body).id);

                  if (!isNotificationExist) {
                      dispatch(setNotification(JSON.parse(response.body)));
                      dispatch(setNewNotification());
                  }
              });

              stompClient?.subscribe(TOPIC_MENTIONS(myProfileId), () => {
                  dispatch(setNewMention());
              });
          });
        }
    }, [myProfileId]);

    useEffect(() => {
      if (tokenStatus === LoadingStatus.FAILED) {
        dispatch(setTokenLoadingStatus(LoadingStatus.LOADED));
        if (!IS_LAUNCH_SOON_PAGE) {
          localStorage.removeItem(TOKEN);
          history.push(ACCOUNT_SIGNIN);
        }
      }
    }, [dispatch, history, tokenStatus])

    const changeBackgroundColor = (background: BackgroundTheme): void => {
        processBackgroundColor(background);
        localStorage.setItem(BACKGROUND, background);
    };

    const changeColorScheme = (color: ColorScheme): void => {
        processColorScheme(color);
        localStorage.setItem(COLOR, color);
    };

    const processBackgroundColor = (background: BackgroundTheme): void => {
        if (background === BackgroundTheme.DEFAULT) {
            setTheme(defaultTheme);
        } else if (background === BackgroundTheme.DIM) {
            setTheme(dimTheme);
        } else if (background === BackgroundTheme.LIGHTS_OUT) {
            setTheme(lightsOutTheme);
        }
    };

    const processColorScheme = (color: ColorScheme): void => {
        if (color === ColorScheme.BLUE) {
            setColorScheme(blueColor);
        } else if (color === ColorScheme.YELLOW) {
            setColorScheme(yellowColor);
        } else if (color === ColorScheme.CRIMSON) {
            setColorScheme(crimsonColor);
        } else if (color === ColorScheme.VIOLET) {
            setColorScheme(violetColor);
        } else if (color === ColorScheme.ORANGE) {
            setColorScheme(orangeColor);
        } else if (color === ColorScheme.GREEN) {
            setColorScheme(greenColor);
        } else {
            setColorScheme(blueColor);
        }
    };

    return (
        <MuiThemeProvider theme={createTheme(deepmerge(theme, colorScheme))}>
            <CssBaseline />
            <div className="App">
                <Layout changeBackgroundColor={changeBackgroundColor} changeColorScheme={changeColorScheme}>
                    <Switch location={background || location}>
                        <Route path={LAUNCH_SOON} component={LaunchSoon} exact />
                        <Route path={ACCOUNT_SIGNIN} component={Authentication} exact />
                        <Route path={ACCOUNT_LOGIN} component={Login} exact />
                        <Route path={ACCOUNT_FORGOT} component={ForgotPassword} />
                        <Route path={HOME} component={Home} exact />
                        <Route path={HOME_CONNECT} component={Connect} exact />
                        <Route path={HOME_TRENDS} component={Trends} exact />
                        <Route path={`${HOME_TWEET}/:id`} component={FullTweet} exact />
                        <Route path={NEWS} component={NewsList} />
                        <Route path={SEARCH} component={Explore} />
                        <Route path={NOTIFICATIONS} component={Notifications} />
                        <Route path={NOTIFICATIONS_TIMELINE} component={NotificationsTimeline} exact />
                        <Route path={`${NOTIFICATION}/:id`} component={NotificationInfo} exact />
                        <Route path={MESSAGES} component={Messages} />
                        <Route path={SETTINGS}
                               render={() => <Settings
                                   changeBackgroundColor={changeBackgroundColor}
                                   changeColorScheme={changeColorScheme} />
                               } />
                        <Route path={BOOKMARKS} component={Bookmarks} />
                        <Route path={`${TOPICS}/:topics`} component={Topics} />
                        <Route path={`${QUOTES}/:tweetId`} component={QuoteTweets} />
                        <Route path={SUGGESTED} component={SuggestedLists} />
                        <Route path={LISTS} component={Lists} exact />
                        <Route path={`${LISTS_MEMBERSHIPS}/:id`} component={ListsMemberships} exact />
                        <Route path={`${LISTS}/:listId`} component={FullList} exact />
                        <Route path={`${PROFILE}/:userId`} component={UserPage} exact />
                        <Route path={`${PROFILE}/:userId${TOPICS}`} component={UserTopics} exact />
                        <Route path={`${USER_FOLLOWERS_YOU_FOLLOW}/:id`} component={FollowersYouKnow} exact />
                        <Route path={`${USER}/:id/:follow`} component={FollowingFollowers} exact />
                    </Switch>
                    {background && <Route path={`${MODAL}/:id`} children={<TweetImageModal />} />}
                    {background && <Route path={`${PROFILE_PHOTO}/:id`} children={<UserImageModal />} />}
                    {background && <Route path={`${PROFILE_HEADER_PHOTO}/:id`} children={<UserImageModal />} />}
                </Layout>
                <ActionSnackbar />
            </div>
        </MuiThemeProvider>
    );
};

export default App;
