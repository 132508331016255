import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    FetchRecentSearchResultActionInterface,
    FetchSearchByTextActionInterface,
    SearchActionsType
} from "./contracts/actionTypes";
import { LoadingStatus } from "../../../types/common";
import { setRecentSearchResult, setSearchLoadingState, setSearchResult } from "./actionCreators";
import { CommonUserResponse, SearchResultResponse } from "../../../types/user";
import { UserApi } from "../../../services/api/user-service/userApi";
import { SEARCH_TERMS, USER_ID } from "../../../constants/common-constants";

export function* fetchSearchByTextRequest({ payload }: FetchSearchByTextActionInterface) {
    try {
        yield put(setSearchLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<SearchResultResponse> = yield call(UserApi.searchByText, payload);
        yield put(setSearchResult(response.data));
    } catch (error) {
        yield put(setSearchLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchRecentSearchResultRequest({ payload }: FetchRecentSearchResultActionInterface) {
    try {
        const userId = localStorage.getItem(USER_ID);
        yield put(setSearchLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<CommonUserResponse[]> = yield call(UserApi.getSearchResults, payload);
        const localStorageItem = localStorage.getItem(SEARCH_TERMS);
        let text = [];
        let tags = [];
        if (localStorageItem && userId) {
          const searchItem = JSON.parse(localStorageItem);
          text = searchItem[userId]?.text ?? [];
          tags = searchItem[userId]?.tags ?? [];
        }
        yield put(setRecentSearchResult({
            text,
            tags,
            users: response.data
        }));
    } catch (error) {
        yield put(setSearchLoadingState(LoadingStatus.ERROR));
    }
}

export function* searchSaga() {
    yield takeLatest(SearchActionsType.FETCH_SEARCH_BY_TEXT, fetchSearchByTextRequest);
    yield takeLatest(SearchActionsType.FETCH_RECENT_SEARCH_RESULT, fetchRecentSearchResultRequest);
}
