import { Typography } from "@material-ui/core";
import { memo, ReactElement } from "react";
import { useSelector } from "react-redux";

import PageHeaderWrapper from "../../../../../components/PageHeaderWrapper/PageHeaderWrapper";
import {
  selectIsNotificationInfoLoading,
  selectNotificationInfoType,
  selectNotificationInfoUserFullName
} from "../../../../../store/ducks/notifications/selectors";
import { NotificationType } from "../../../../../types/common";

const NotificationInfoHeader = memo((): ReactElement => {
    const isLoading = useSelector(selectIsNotificationInfoLoading);
    const notificationType = useSelector(selectNotificationInfoType);
    const userFullName = useSelector(selectNotificationInfoUserFullName);

    const getNotificaionType = () => {
      if (notificationType === NotificationType.LIKE) {
        return "Liked";
      } else if (notificationType === NotificationType.REJECT) {
        return "Tweet Rejected";
      } else if (notificationType === NotificationType.POST) {
        return "Tweet Post";
      } else {
        return "Retweeted";
      }
    }

    return (
        <PageHeaderWrapper backButton>
            {!isLoading && (
                <div>
                    <Typography variant="h5" component={"div"}>
                        {getNotificaionType()}
                    </Typography>
                    {((notificationType !== NotificationType.POST) && (notificationType !== NotificationType.REJECT)) && (
                      <Typography variant="caption" component={"div"}>
                        by {userFullName}
                      </Typography>
                    )}
                </div>
            )}
        </PageHeaderWrapper>
    );
});

export default NotificationInfoHeader;
