const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const usaTimeZones = [
    'America/New_York', // Eastern Time
    'America/Chicago',  // Central Time
    'America/Denver',   // Mountain Time
    'America/Los_Angeles', // Pacific Time
    'America/Anchorage', // Alaska Time
    'America/Phoenix',  // Arizona (Mountain Time without DST)
    'Pacific/Honolulu'  // Hawaii-Aleutian Time
];

// Only For USA
// export const IS_USA_TIME = usaTimeZones.includes(timeZone); 

// For now,Set all region
export const IS_USA_TIME = true;
export const APP_NAME = IS_USA_TIME ? "Twitter" :"Bluebird";
export const PAGE_TOTAL_COUNT = "page-total-count";

export const HOVER_DELAY_FETCH = 500;
export const HOVER_DELAY_SHOW = 1000;

export const MAX_TEXT_LENGTH = 280;

export const HOUR_MINUTE_AMPM = "hh:mm a";
export const MONTH_DAY_YEAR = " MMM dd, yyyy";

// localStorage
export const TOKEN = "token";
export const SEARCH_TERMS = "searchTerms";
export const BACKGROUND = "background";
export const COLOR = "color";
export const CONVERSATION_INFO = "conversationInfo";
export const USER_ID = "userId";

export const GIPHY_API_KEY = "GZtggGLShFAsldhNlgteCQgfD27nRb5A";
export const GOOGLE_RECAPTCHA_KEY = "6LeGej4qAAAAAABKGxLw5FY4DGAaquNMtfpxcQTy";

export const ALLOWED_PROFILE_IMAGE_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const PRODUCTION_DOMAIN = "opbluebird.com";

export const NEWS_CATEGORY_LIST = ["general", "business", "entertainment", "health", "science", "sports", "technology"];

export const countries: {[key: string]: string} = {
    ar: "Argentina",
    au: "Australia",
    at: "Austria",
    ba: "Belgium",
    br: "Brazil",
    bg: "Bulgaria",
    ca: "Canada",
    cn: "China",
    co: "Colombia",
    cz: "Czech Republic",
    eg: "Egypt",
    fr: "France",
    de: "Germany",
    gr: "Greece",
    hk: "Hong Kong",
    hu: "Hungary",
    in: "India",
    id: "Indonesia",
    ie: "Ireland",
    il: "Isrel",
    it: "Italy",
    jp: "Japan",
    lv: "Latvia",
    lt: "Lithunia",
    my: "Malaysia",
    mx: "Mexico",
    ma: "Morocco",
    nl: "Netherlands",
    nz: "New Zealand",
    ng: "Nigeria",
    no: "Norway",
    ph: "Philippines",
    pl: "Poland",
    pt: "Portugal",
    ro: "Romania",
    sa: "Saudi Arabia",
    rs: "Serbia",
    sg: "Singapore",
    sk: "Solvakia",
    sl: "Solvenia",
    za: "South Africa",
    kr: "South Korea",
    se: "Sweden",
    ch: "Switzerland",
    tw: "Taiwan",
    th: "Thailand",
    tr: "Turkey",
    ae: "UAE",
    ua: "Ukraine",
    gb: "United Kingdom",
    us: "United States",
    ve: "Venuzuela"
}
