import { SEARCH_TERMS, USER_ID } from "../../constants/common-constants";
import { SearchTermsRequest } from "../../store/ducks/search/contracts/state";

export const addToLocalStorage = (term: "users" | "tags" | "text", item: number | string): void => {
    const localStorageItem = localStorage.getItem(SEARCH_TERMS);
    const userId = localStorage.getItem(USER_ID) || -1;

    if (localStorageItem) {
        const searchTerms: Record<string, SearchTermsRequest> = JSON.parse(localStorageItem);
        if (searchTerms[userId]?.[term]) {
            localStorage.setItem(SEARCH_TERMS, JSON.stringify({
                ...searchTerms,
                [userId]: {
                    ...searchTerms[userId],
                    [term]: Array.from(new Set([item, ...(searchTerms[userId][term] || [])]))
                }
            }));
        } else {
            localStorage.setItem(SEARCH_TERMS, JSON.stringify({
                ...searchTerms,
                [userId]: {
                    ...searchTerms[userId],
                    [term]: [item]
                }
            }));
        }
    } else {
        localStorage.setItem(SEARCH_TERMS, JSON.stringify({
            [userId]: { [term]: [item] }
        }));
    }
};
