import { ClickAwayListener, List } from "@material-ui/core";
import { memo, ReactElement } from "react";
import { useSelector } from "react-redux";
import ActionIconButton from "../../../components/ActionIconButton/ActionIconButton";
import { useClickAway } from "../../../hook/useClickAway";
import { EditIcon } from "../../../icons";
import {
  selectUserProfileIsFollower,
  selectUserProfileIsPrivateProfile,
  selectUserProfileIsUserBlocked,
  selectUserProfileUsername
} from "../../../store/ducks/userProfile/selectors";
import { useGlobalStyles } from "../../../util/globalClasses";
import AddUserToListsButton from "./AddUserToListsButton/AddUserToListsButton";
import BlockUserButton from "./BlockUserButton/BlockUserButton";
import MuteUserButton from "./MuteUserButton/MuteUserButton";
import { useUserPageActionsStyles } from "./UserPageActionsStyles";
import ViewUserListsButton from "./ViewUserListsButton/ViewUserListsButton";

const UserPageActions = memo((): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useUserPageActionsStyles();
    const username = useSelector(selectUserProfileUsername);
    const isUserBlocked = useSelector(selectUserProfileIsUserBlocked);
    const isPrivateProfile = useSelector(selectUserProfileIsPrivateProfile);
    const isFollower = useSelector(selectUserProfileIsFollower);
    const { open, onClickOpen, onClickClose } = useClickAway();

    return (
        <ClickAwayListener onClickAway={onClickClose}>
            <div className={classes.container}>
                <span className={globalClasses.userPageIconButton}>
                    <ActionIconButton actionText={"More"} onClick={onClickOpen} icon={EditIcon} />
                </span>
                {open && (
                    <div className={classes.dropdown}>
                        <List>
                            {(!isPrivateProfile || isFollower) && (
                                <>
                                    {/* <ViewUserTopicsButton /> */}
                                    <AddUserToListsButton />
                                    <ViewUserListsButton />
                                    {/* <UserItemAction title={"View Moments"} icon={MomentsIcon} />
                                    {!isUserBlocked && (
                                        <>
                                            <UserItemAction title={"Share profile via..."} icon={ShareIcon} />
                                            <CopyProfileLinkButton onCloseUserPageActions={onClickClose} />
                                        </>
                                    )} */}
                                </>
                            )}
                            {!isUserBlocked && (
                                <MuteUserButton onCloseUserPageActions={onClickClose} />
                            )}
                            <BlockUserButton />
                            {/* <UserItemAction title={`Report @${username}`} icon={ReportIcon} /> */}
                        </List>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
});

export default UserPageActions;
