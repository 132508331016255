import { makeStyles } from "@material-ui/core";
import { BACKGROUND } from "../../constants/common-constants";
import { BackgroundTheme } from "../../types/common";

export const useVeracityModalStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .MuiIconButton-root": {
      "& svg": {
        color: theme.palette.text.secondary,
      }
    },
    alignContent: "center"
  },
  container: {
    display: "flex",
    gap: "12px",
    border: "none",
    marginBottom: "12px",
  },
  dropdown: {
    padding: 0,
    position: "absolute",
    width: 290,
    // height: 208,
    top: 10,
    right: 10,
    zIndex: 2,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px",
    "& .MuiListItem-root": {
      height: 52,
      "& svg": {
        marginRight: 15
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  content: {
    top: "-20%"
  },
  dialogContent: {
    width: 598,
    minHeight: 100,
    padding: "10px 20px 10px 20px"
  },
  postCounter: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontWeight: 600,
    width: "50px",
    height: "50px",
    border: "none",
    borderRadius: "8px"
  },
  postReport: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "12px"
  },
  postReportList: {
    "& .MuiListItem-root": {
      paddingInline: 0,
      "& .MuiListItemIcon-root": {
        minWidth: "unset"
      },
      "& .MuiListItemText-root": {
        marginLeft: "6px"
      },
    }
  },
  typography: {
    minWidth: "300px",
    maxWidth: "325px",
    padding: theme.spacing(1),
  },
  referenceLink: {
    textDecoration: "underline",
    wordBreak: 'break-all'
  },
  userContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    border: "none",
    margin: "12px 0px"
  },
  userCountReport: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    border: "none",
    marginBottom: "12px"
  },
  userScoreCount: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontWeight: 600,
    width: "50px",
    minWidth: "50px",
    maxWidth: "50px",
    height: "50px",
    border: "none",
    borderRadius: "50%",
    zIndex: 1
  },
  userProfileReport: {
    display: "flex",
    gap: "12px",
    border: "none",
    "& .MuiTypography-h6": {
        fontWeight: "500",
        color: "#333333"
    }
  },
  userAvatar: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontWeight: 600,
    width: "50px",
    minWidth: "50px",
    maxWidth: "50px",
    height: "50px",
    border: "none",
    borderRadius: "50%",
    zIndex: 1
  },
  stepperline:{
    width: "2px",
    backgroundColor: "grey",
    position: "absolute",
    top: "25px",
    bottom: "25px",
    left: "25px",
    zIndex: 0,
  },
  divider: {
    backgroundColor: "#D9D9D9"
  },
  tweetScore: {
    fontSize: "16px",
    color: localStorage.getItem(BACKGROUND) !== BackgroundTheme.DEFAULT ? "inherit" : "#536471",
    // color: "#",
    fontWeight: 400,
    cursor: "pointer",
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    padding: "3px 6px",
    borderRadius: "10px",
    "& span": {
      height: "12px",
      width: "12px",
      display: "inline-block",
      borderRadius: "50%",
      marginRight: "2px",
    }
  },
}));