export interface PageableResponse<T> {
    items: T;
    pagesCount: number;
}

export enum LoadingStatus {
    LOADED = "LOADED",
    LOADING = "LOADING",
    ERROR = "ERROR",
    NEVER = "NEVER",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

export enum ColorScheme {
    BLUE = "BLUE",
    YELLOW = "YELLOW",
    CRIMSON = "CRIMSON",
    VIOLET = "VIOLET",
    ORANGE = "ORANGE",
    GREEN = "GREEN",
}

export enum BackgroundTheme {
    DEFAULT = "DEFAULT",
    DIM = "DIM",
    LIGHTS_OUT = "LIGHTS_OUT",
}

export interface Image {
    id: number;
    src: string;
}

export enum ReplyType {
    EVERYONE = "EVERYONE",
    FOLLOW = "FOLLOW",
    MENTION = "MENTION"
}

export enum LinkCoverSize {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE"
}

export enum NotificationType {
    LIKE = "LIKE",
    RETWEET = "RETWEET",
    REPLY = "REPLY",
    FOLLOW = "FOLLOW",
    TWEET = "TWEET",
    LISTS = "LISTS",
    POST = "POST",
    REJECT = "REJECT",
}
export interface SameFollowerResponse {
    id: number;
    fullName: string;
    username: string;
    avatar: string;
}

export interface CommonStatus {
  isLoading: boolean;
  isSuccess: boolean;
  isFail: boolean;
}

export enum PHOTO_TAGGING {
  EVERYONE = "EVERYONE",
  FOLLOWERS = "FOLLOWERS",
}